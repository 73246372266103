import moment from "moment";

import { APP_USER } from "./constants";

// Local storage operations
export const useLocalStorage = {
  set: (key: string, data: any) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key: string) => {
    const data: any = JSON.parse(localStorage.getItem(key)!);

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key: string) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getUserDetails = () => {
  const user = useLocalStorage.get(APP_USER);

  return user ? user : null;
};

export const getRequestError = (error: any) => {
  const { response, message } = error;
  if (response && (response.data.code === 401 || response.status === 401)) {
    if (!response.data.message?.includes("token")) {
      return response.data?.message;
    } else {
      logoutUser();
    }
  } else if (response && response.data.errors && response.data.errors[0]) {
    return response.data.errors[0].message;
  } else if (response && response.data.message) {
    return response.data.message;
  } else if (response && response.data.error) {
    return response.data.error;
  } else if (message) {
    return message;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const logoutUser = () => {
  useLocalStorage.remove(APP_USER);
  window.location.reload();
};

export const goBackAction = () => {
  window.history.go(-1);
};

export const printerAction = () => {
  window.print();
};

export const openExternalLinkAction = (url: string) => {
  window.open(url, "_blank", "noopener, noreferrer");
};

export const formatDateInWords = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour: any = current_date.getHours();
  let mins: any = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : "";
};

export const formatNumber = (n: number, decimals: number) => {
  return (
    n &&
    Number(n)
      .toFixed(decimals || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
  );
};

export const firstLetter = (letter: string) => {
  var str = letter;
  var res = str?.substring(0, 1);
  return res;
};

export const getInitials = (letter: string) => {
  if (letter.includes("--")) return "- -";
  var names = letter.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const hexCodeGenerate = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  return "#" + randomColor;
};

export const dateToFromNow = (date: Date) => {
  // get from-now for this date
  var fromNow = moment(date).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(date).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
};

// Form Validations
export const emailFormValidation = (required: boolean) => {
  return {
    required: required ? "Email address is required" : false,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  };
};
export const nameFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    pattern: {
      value: /^[A-Za-z]+$/,
      message: "Invalid username",
    },
    minLength: {
      value: 2,
      message: "Must be more than 1 character",
    },
  };
};
export const textFormValidation = (required: boolean, minLength?: number) => {
  return {
    required: required ? "This field is required" : false,
    minLength: minLength
      ? {
          value: minLength + 1,
          message: `Must be more than ${minLength} character(s)`,
        }
      : {
          value: 2,
          message: "Must be more than 1 character",
        },
  };
};
export const numberFormValidation = (
  required: boolean,
  minLength: number,
  maxLength: number,
  min?: number,
  max?: number
) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: minLength,
      message: `Minimum characters is ${minLength}`,
    },
    maxLength: {
      value: maxLength,
      message: `Maximum characters is ${maxLength}`,
    },
    min: {
      value: min || 0,
      message: `Minimum value is ${min || 0}`,
    },
    max: {
      value: max || 10000000000000000000,
      message: `Maximum value is ${max || 10000000000000000000}`,
    },
  };
};
export const passwordFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: 8,
      message: "Must use at least 8 characters",
    },
  };
};
export const basicFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
  };
};

export const urlValidation = (userInput: string) => {
  var res = userInput?.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) {
    return true;
  }
  return false;
};

export const convertStringToArray = (input: string) => {
  // Split the string by semicolon delimiter
  if (typeof input === "string") {
    // If input is a string, split it by semicolon delimiter and convert to numbers
    const values = input?.includes(";") ? input.split(";") : input.split(",");

    return values.map((value) => {
      return value.trim();
    });
  }
};

export const toCapitalize = (str: string) => {
  let result = str.charAt(0).toUpperCase() + str.slice(1);

  return result;
};

export const removeWord = (originalString: string, wordToRemove: string) => {
  const words = originalString?.split(", ");
  const filteredWords = words?.filter((word) => word !== wordToRemove);
  return filteredWords.join(", ").trim();
};

export const extractUniqueWord = (
  stringArr: string[],
  string: string
): string => {
  const words2 = string?.split(", ");

  const uniqueToSecond = words2?.filter((word) => !stringArr?.includes(word));

  if (!uniqueToSecond) return "";

  return uniqueToSecond.join(", ").trim();
};
