import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../redux";
import { sendOnboardingQuestionsAction } from "../../redux/auth/user-slice";

import PageTitle from "../../components/page-title";
import Carousel from "../../components/carousel";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Progress from "../../components/progress";

import { onboardingQuestionsAndOptions } from "../../utils/appdata";
import { extractUniqueWord, removeWord } from "../../utils/functions";
// import { SHOPIFY_INSTALLATION_URL } from "../../utils/constants";

const OnboardingQuestionsPage = () => {
  const [isOthers, setIsOthers] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userSlice);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const store_url = params.get("store_url");

  const currentQuestionData = onboardingQuestionsAndOptions[questionNumber];

  useEffect(() => {
    window.scroll(0, 0);
    if (!store_url) return window.location.assign("/");
    if (checkIfOthersSelected()) setIsOthers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionNumber]);

  useEffect(() => {
    console.log("answers:", answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const checkIfOthersSelected = () => {
    return answers[currentQuestionData.name] &&
      !currentQuestionData.options.includes(answers[currentQuestionData.name])
      ? true
      : false;
  };

  const isBtnDisabled = () => {
    return answers[currentQuestionData.name] &&
      answers[currentQuestionData.name] !== "Other"
      ? false
      : true;
  };

  const handleClick = async () => {
    if (onboardingQuestionsAndOptions.length - 1 === questionNumber) {
      setIsSubmitting(true);
      const answerData = { ...answers, slug: user.company_slug };
      await dispatch(sendOnboardingQuestionsAction(answerData))
        .then((res) => {
          if (res?.success) {
            if (store_url === "none") {
              window.location.assign("/onboarding");
            } else {
              window.location.href = `https://api.konvas.ai/ShopifyAuth/installation/${user.company_slug}?shopify_store=${store_url}`;
            }
          }
        })
        .catch(() => {
          setIsSubmitting(false);
        });
      return;
    }
    setIsOthers(false);
    setQuestionNumber((prev) =>
      prev < onboardingQuestionsAndOptions.length - 1 ? prev + 1 : prev
    );
  };

  const handlePrev = () => {
    setIsOthers(false);
    setQuestionNumber((prev) => prev - 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setIsOthers((prev) => {
      return value === "Other" || type === "text"
        ? true
        : prev && type === "checkbox"
        ? true
        : false;
    });

    setAnswers((prev) => {
      if (prev.hasOwnProperty(name) && type === "checkbox") {
        const existingValues = prev?.[name];
        const newValues =
          // value === "Other"
          //   ? existingValues:
          existingValues?.includes(value)
            ? removeWord(existingValues, value)
            : `${value}, ${existingValues}`;
        return { ...prev, [name]: newValues };
      } else if (type === "radio") {
        if (isOthers && value === "Other") return { ...prev, [name]: "" };
      } else if (prev.hasOwnProperty(name) && type === "text") {
        const existingValues = prev?.[name];
        console.log("value", value);
        console.log("existingValues", existingValues.split(", ")[0]);
        console.log(
          "existingValues greater than value",
          value.length >= 2 &&
            existingValues.split(", ")[0].length < value.length
        );

        const currentValues =
          value.length >= 2 &&
          existingValues.split(", ")[0].length < value.length
            ? removeWord(existingValues, value.slice(0, -1))
            : removeWord(existingValues, existingValues.split(", ")[0]);

        const newValues = `${value}, ${currentValues}`;
        return {
          ...prev,
          [name]: currentQuestionData.type === "single" ? value : newValues,
        };
      }
      return { ...prev, [name]: value };
    });
  };

  return (
    <React.Fragment>
      <PageTitle title="Sign Up" />

      <div className="sign_in_page sign_up_page">
        <div className="left_side">
          <Carousel />
        </div>

        <div className="question_container">
          <div className="progress">
            <Progress
              value={
                ((questionNumber + 1) / onboardingQuestionsAndOptions.length) *
                100
              }
            />
          </div>

          {
            onboardingQuestionsAndOptions.map(
              ({ name, type, question, options }) => (
                <div className="question" key={name}>
                  <p className="question_title">{question}</p>

                  <div className="options_container">
                    {options.map((option) => (
                      <div className="options" key={option}>
                        <FormInput
                          readOnly={false}
                          name={name}
                          type={type === "single" ? "radio" : "checkbox"}
                          id={option}
                          label={option}
                          value={option}
                          checked={
                            option === answers[name] ||
                            (type === "single" &&
                              option === "Other" &&
                              checkIfOthersSelected()) ||
                            (type === "multiple" &&
                              answers?.[name]?.includes(option))
                          }
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                  </div>

                  <>
                    {isOthers ? (
                      <div className="other_input">
                        <p>Specify below</p>
                        <FormInput
                          readOnly={false}
                          name={name}
                          value={
                            answers[name] === "Other"
                              ? ""
                              : type === "multiple"
                              ? extractUniqueWord(
                                  currentQuestionData.options,
                                  answers[name]
                                )
                              : answers[name]
                          }
                          type="text"
                          onChange={handleChange}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>

                  <div className="action">
                    {questionNumber !== 0 ? (
                      <Button
                        text="Back"
                        className="back_button"
                        onClick={handlePrev}
                        leftIcon="arrowBack"
                        iconSize="40px"
                      />
                    ) : (
                      <></>
                    )}
                    <Button
                      text="Proceed"
                      onClick={handleClick}
                      disabled={isBtnDisabled()}
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              )
            )[questionNumber]
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnboardingQuestionsPage;
