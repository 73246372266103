type ProgressProps = {
  value: number;
};

const Progress = ({ value }: ProgressProps) => {
  return (
    <div className="progress_container">
      <div className="progress_inner" style={{ width: `${value}%` }}></div>
    </div>
  );
};

export default Progress;
