import OnboardingPage from "./onboarding";
import DashboardPage from "./home";
import SegmentsPage from "./segments";
// import SegmentInfoPage from "./segments/segment-info";
import InsightsPage from "./insights";
// import InsightInfoPage from "./insights/insight-info";
import CampaignsPage from "./campaigns";
import CustomersPage from "./customers";
import CustomersInfoPage from "./customers/customer-info";
import SettingsPage from "./settings";
import ShopifyOnboardingPage from "./shopify-onboarding";
import OnboardingQuestionsPage from "./onboarding-questions";
import NotificationsPage from "./notifications";

type Props = {
  icon: string;
  label: string;
  to?: string | null;
  href?: string | null;
  showCount?: boolean;
  component?: () => JSX.Element;
  unlist?: boolean;
}[];

const Navigations: Props = [
  {
    icon: "grid",
    label: "ONBOARDING",
    to: "/onboarding",
    component: OnboardingPage,
    unlist: true,
  },
  {
    icon: "grid",
    label: "",
    to: "/shopify-onboarding",
    component: ShopifyOnboardingPage,
    unlist: true,
  },
  {
    icon: "grid",
    label: "",
    to: "/onboarding-questions",
    component: OnboardingQuestionsPage,
    unlist: true,
  },
  {
    icon: "",
    label: "",
    to: "/notifications",
    component: NotificationsPage,
    unlist: true,
  },
  {
    icon: "analytics",
    label: "DASHBOARD",
    to: "/home",
    component: DashboardPage,
  },
  {
    icon: "grid",
    label: "SEGMENTS",
    to: "/segments",
    component: SegmentsPage,
  },
  {
    icon: "grid",
    label: "SEGMENTS",
    to: "/segments/:id",
    component: () => <></>,
    unlist: true,
  },
  {
    icon: "star",
    label: "INSIGHTS",
    to: "/insights",
    component: InsightsPage,
  },
  {
    icon: "grid",
    label: "INSIGHTS",
    to: "/insights/:id",
    component: () => <></>,
    unlist: true,
  },
  {
    icon: "mail",
    label: "CAMPAIGNS",
    to: "/campaigns",
    component: CampaignsPage,
  },
  {
    icon: "user",
    label: "CUSTOMERS",
    to: "/customers",
    component: CustomersPage,
  },
  {
    icon: "user",
    label: "CUSTOMERS",
    to: "/customers/:id",
    component: CustomersInfoPage,
    unlist: true,
  },
  {
    icon: "settings",
    label: "Settings",
    to: "/settings",
    component: SettingsPage,
  },
  {
    icon: "logout",
    label: "Log Out",
    to: "/",
  },
];

export default Navigations;
