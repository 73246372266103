import { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";

import Sidebar from "../components/sidebar";

import NavRoutes from "./routes";

const Dashboard = () => {
  const location = useLocation();

  const { isSidebarOpen } = useSelector((state: any) => state.componentsSlice);

  const [hideSidebar, setHideSidebar] = useState(true);

  useEffect(() => {
    const paths = [
      "/onboarding",
      "/shopify-onboarding",
      "/onboarding-questions",
    ];

    if (paths.some((v) => location.pathname.includes(v))) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <div
        className={classNames("dashboard", {
          collapse_sidebar: !isSidebarOpen,
          hide_sidebar: hideSidebar,
        })}
      >
        <Sidebar />

        <div className="dashboard_content">
          <NavRoutes />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Dashboard;
