export const onboardingQuestionsAndOptions = [
  {
    type: "single",
    name: "primary_marketing_goal",
    question: "What is your primary marketing goal?",
    options: [
      "Increase sales and revenue",
      "New Customers (Reduce Customer Acquisition Cost)",
      "Increase existing customer retention rate",
      "Increase product discoverability",
      "Other",
    ],
  },
  {
    type: "single",
    name: "biggest_ecommerce_challenge",
    question:
      "What is currently the biggest challenge in managing your e-commerce business?",
    options: [
      "Attracting new customers",
      "Converting visitors into paying customers",
      "Retaining existing customers and encouraging repeat purchases",
      "Managing Marketing Campaigns including copy development, personalization, and optimization.",
      "Other",
    ],
  },

  {
    type: "multiple",
    name: "marketing_channel",
    question:
      "Which marketing channel(s) do you use to engage your customers? (Select all that applies)",
    options: [
      "Email",
      "Facebook",
      "SMS",
      "Google Ads",
      "Instagram",
      "Tiktok",
      "Other",
    ],
  },
  {
    type: "single",
    name: "marketing_tool",
    question: "What do you currently use for email marketing? (if applicable)",
    options: [
      "Klaviyo",
      "Mailchimp",
      "Apollo.io",
      "Wunderkind",
      "None",
      "Other",
    ],
  },
  // {
  //   type: "single",
  //   name: "current_annual_revenue",
  //   question: "What is your current annual revenue range?",
  //   options: [
  //     "Less than $100k",
  //     "$100k - $499k",
  //     "$500k - $999k",
  //     "$1M - $4.9M",
  //     "$5M+",
  //   ],
  // },
];
