import axios from "axios";

import { getUserDetails } from "../utils/functions";

const companySlug = getUserDetails()?.company_slug;

export const API_URLS = {
  createUser: "/register",
  createShopifyUser: "/auths/register_shopify",
  loginUser: "/login",
  shopifyLogin: "/auths/shopify_login",
  googleLogin: "/auths/google_auth",
  googleRegister: "/auths/google_reg",
  logoutUser: "/logout",
  sendOnboardingQuestions: "/auths/onboard",
  updateProfile: `/settings/update_profile`,
  subscription: `/subscription/user`,
  plans: `/subscription/all_plans`,
  upgradePlan: `/subscription/payment_success`,
  changePassword: `/settings/change_password`,
  aiSyncToVectorDB: `https://konvas.ai/ai/api/integrations/${companySlug}`,
  aiSyncResponse: `/shopify_sync/${companySlug}`,
  getShopifyDetails: "/brands/shopify_details/",
  getSetupProgress: `/brands/setup_progress/${companySlug}`,
  getDailyVideo: `https://app.konvas.ai/daily-report-videos/${companySlug}`,
  getNoDailyVideo: `https://app.konvas.ai/daily-report-videos/nodata.mp4`,
  getHomeInsights: `/insights/top_insights/${companySlug}`,
  getChartData: `/campaigns/dashboard_analytics/${companySlug}`,
  getSegments: `/segments/all/${companySlug}`,
  getSegmentById: `/segments/get_by_id`,
  generateAISegment: `https://konvas.ai/ai/api/segments/${companySlug}`,
  generateCustomSegment: `https://konvas.ai/ai/api/segments/custom/${companySlug}`,
  addSegment: "/segments/add",
  deleteSegment: `/segments/delete`,
  getInsights: `/insights/all/${companySlug}`,
  getInsightById: `/insights/get_by_id`,
  generateAIInsight: `https://konvas.ai/ai/api/insights/${companySlug}`,
  generateCustomInsight: `https://konvas.ai/ai/api/insights/custom/${companySlug}`,
  addInsight: "/insights/add",
  updateInsightStatus: `/insights/updateStatus`,
  deleteInsight: `/insights/delete`,
  getCampaigns: `/campaigns/all/${companySlug}`,
  getCampaignById: `/campaigns/get_by_id`,
  getPersonalizedCampaignById: `/campaigns/get_personalized_emails`,
  generateAICampaign: `https://konvas.ai/ai/api/campaigns/create-ai-generated/${companySlug}`,
  generateAICustomCampaign: `https://konvas.ai/ai/api/campaigns/custom`,
  generateAISegmentCampaign: `https://konvas.ai/ai/api/campaigns/segment`,
  addCustomCampaign: `/campaigns/add_by_custom`,
  addSegmentCampaign: `/campaigns/add_by_segment`,
  deleteCampaign: `/campaigns/delete`,
  rewriteCampaignMsg: `https://konvas.ai/ai/api/campaigns/rewrite-message-body`,
  rewriteUnsavedCampaignBody: `https://konvas.ai/ai/api/campaigns/rewrite-unsaved-campaign-body`,
  updateCampaign: `/campaigns/update`,
  endCampaign: `/campaigns/end`,
  continueCampaign: "/campaigns/continue",
  pauseCampaign: `/campaigns/pause`,
  scheduleCampaign: `/campaigns/schedule`,
  sendCampaignEmail: `/campaigns/bulk_email`,
  campaignEmailAnalytics: `/campaigns/analytics`,
  getCustomers: `/customers/all/${companySlug}`,
  getCustomerById: `/customers/get_by_id`,
  getCustomerInsight: `https://konvas.ai/ai/api/insights/unique-customer-insight`,
};

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

// Request interceptors for API calls
API.interceptors.request.use(
  async (config: any) => {
    const tokenData = getUserDetails();

    config.headers = {
      Authorization: tokenData
        ? `${tokenData.token_type} ${tokenData.token}`
        : ``,
      "X-API-KEY": process.env.REACT_APP_API_KEY,
    };
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

type GetRequestProps = {
  url: string;
  params: any;
};
export const getRequest = async ({ url, params }: GetRequestProps) => {
  const requestResponse = await API({
    url,
    method: "get",
    params,
  });

  return requestResponse;
};

type PostRequestProps = {
  url: string;
  params: any;
  data: any;
  formData: any;
};
export const postRequest = async ({
  url,
  params,
  data,
  formData,
}: PostRequestProps) => {
  const requestResponse = await API({
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
    url,
    method: "post",
    params,
    data,
  });

  return requestResponse;
};

type PatchRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const patchRequest = async ({
  url,
  params,
  data,
}: PatchRequestProps) => {
  const requestResponse = await API({
    url,
    method: "patch",
    params,
    data,
  });

  return requestResponse;
};

type DeleteRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const deleteRequest = async ({
  url,
  params,
  data,
}: DeleteRequestProps) => {
  const requestResponse = await API({
    url,
    method: "delete",
    params,
    data,
  });

  return requestResponse;
};
