import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Loader from "../loader";
import FormSelect from "../form-select";
import Button from "../button";
import EnterPromptForm from "../enter-prompt";

import {
  generateAICampaignAction,
  generateAICustomCampaignAction,
  rewriteUnsavedCampaignBodyAction,
} from "../../redux/dashboard/campaigns-slice";
import { getSegmentsAction } from "../../redux/dashboard/segments-slice";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  initialCampaignPromptFromInsight: string;
  onSubmit: (payload: any) => void;
};

const NewCampaignModal = ({
  isOpen,
  close,
  initialCampaignPromptFromInsight,
  onSubmit,
}: Props) => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { isCreatingCampaign, isSavingCampaign, isSavingCampaignSuccessful } =
    useSelector((state: any) => state.campaignsSlice);

  const { segments } = useSelector((state: any) => state.segmentsSlice);

  const [insightCampaignLoading, setInsightCampaignLoading] = useState(false);
  const [step, setStep] = useState<Number>(1);
  const [campaignType, setCampaignType] = useState<any>(null);
  const [segment, setSegment] = useState<any>(null);
  const [promptText, setPromptText] = useState<string>("");
  const [generatedData, setGeneratedData] = useState<any>({});
  const [isRewriting, setIsRewriting] = useState<any>(false);
  const [isRewritingMessageBody, setIsRewritingMessageBody] =
    useState<any>(false);
  const [isModifying, setIsModifying] = useState<any>(false);

  useEffect(() => {
    if (segments?.length < 1) dispatch(getSegmentsAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("segment:", JSON.parse(segments?.[0]?.customers), segment);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [segment]);

  useEffect(() => {
    resetModal();
  }, [isOpen]);

  useEffect(() => {
    if (initialCampaignPromptFromInsight) {
      setInsightCampaignLoading(true);
      console.log("initialPrompt", initialCampaignPromptFromInsight);
      handleGenerateCampaignDetails(initialCampaignPromptFromInsight);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCampaignPromptFromInsight]);

  const resetModal = () => {
    setStep(1);
    setCampaignType(null);
    setSegment(null);
  };

  const campaignTypeOptions = [
    { label: "SEGMENT BASED CAMPAIGN", value: 1 },
    { label: "CUSTOM CAMPAIGN", value: 2 },
  ];

  const selectCampaignType = (selected: any) => {
    setCampaignType(selected);
    setStep(2);
  };

  const handleSetGeneratedData = (data: any) => {
    if (data) {
      setGeneratedData(data);
    }
  };

  const generateWithAI = () => {
    setIsRewriting(true);

    dispatch(generateAICampaignAction()).then((res) => {
      if (res?.success) {
        handleSetGeneratedData(res?.data);
        setPromptText("");
        setStep(3);
      }

      setIsRewriting(false);
    });
  };

  const handleRewriteMessageBody = () => {
    setIsRewritingMessageBody(true);
    const payload = {
      campaign_body: generatedData.campaign_body,
    };

    dispatch(rewriteUnsavedCampaignBodyAction(payload)).then((res) => {
      if (res?.success) {
        setGeneratedData({ ...generatedData, campaign_body: res?.data });
      }
      setIsRewritingMessageBody(false);
    });
  };

  const handleGenerateCampaignDetails = (insightCampaignPrompt?: string) => {
    if (step === 3) setIsRewriting(true);

    let payload = {};

    let customers = segment?.customers;

    if (segment?.value) {
      console.log("customer_length:", customers.length);
      //send only the last 100 customers to the endpoint if more than 100
      if (customers.length > 100) customers = customers.slice(-100);
      payload = {
        company_slug: user?.company_slug,
        segment_id: segment?.value,
        segment_name: segment?.label,
        segment_description: segment?.description,
        customers,
      };
    } else if (insightCampaignPrompt) {
      payload = {
        user_query: insightCampaignPrompt,
        company_slug: user?.company_slug,
      };
    } else {
      payload = {
        user_query: step === 3 ? generatedData?.campaign_goal : promptText,
        company_slug: user?.company_slug,
      };

      console.log("payload", payload);
    }

    dispatch(generateAICustomCampaignAction(payload))
      .then((res) => {
        if (res?.success) {
          handleSetGeneratedData(res?.data);
          setPromptText("");
          setInsightCampaignLoading(false);
          setStep(3);
        }
        setIsModifying(false);
        setIsRewriting(false);
      })
      .catch((err) => {
        setInsightCampaignLoading(false);
      });
  };

  const handleSubmit = (status: string) => {
    setStep(4);

    let payload: any = {
      business_slug: user?.company_slug,
      campaign_title: generatedData.campaign_title,
      // ai_details: `<p><strong>RATIONALE / MARKETING STRATEGY:</strong> ${generatedData.rationale}</p><p><strong>TARGET AUDIENCE:</strong> ${generatedData.target_audience}</p><p><strong>SUGGESTED BUDGET:</strong> ${generatedData.budget}</p><p><strong>METRICS:</strong> ${generatedData.metrics}</p>`,
      ai_details: `<p><strong>RATIONALE / MARKETING STRATEGY:</strong> ${generatedData.rationale}</p><p><strong>TARGET AUDIENCE:</strong> ${generatedData.target_audience}</p><p><strong>METRICS:</strong> ${generatedData.metrics}</p>`,
      campaign_subject: generatedData.campaign_subject,
      campaign_body: generatedData.campaign_body,
      campaign_recipients: generatedData.campaign_recipients,
      campaign_duration: generatedData.campaign_duration,
      start_date: generatedData.start_date,
      end_date: generatedData.end_date,
      status,
    };
    if (segment?.value) payload.segment_id = segment?.value;

    onSubmit({
      segment_id: segment?.value,
      payload,
    });
  };
  useEffect(() => {
    if (step === 4 && isSavingCampaignSuccessful === false) {
      setStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingCampaignSuccessful]);

  const segmentOptions = segments?.map((item: any) => ({
    label: item?.segment_name,
    value: item?.id,
    description: item?.segment_description,
    customers: JSON.parse(item?.customers),
  }));

  const modalTitle = () => {
    if (step === 1) {
      return "New Campaign";
    } else {
      return campaignType?.label;
    }
  };

  const rationaleContent = generatedData?.rationale
    ?.replace(/:/g, ":\n")
    ?.replace(".", ".\n\n");

  const campaignMsgContent = generatedData?.campaign_body
    ?.replace(/\\n/g, "\n\n")
    ?.replace(/\\n \\n/g, "\n\n\n");

  return (
    <Modal className="new_campaign_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          title={modalTitle()}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      {insightCampaignLoading ? (
        <div
          style={{
            width: "100%",
            height: "15rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {step === 1 && (
            <div className="modal_body step_one">
              <div
                className="option"
                onClick={() => selectCampaignType(campaignTypeOptions[0])}
              >
                {campaignTypeOptions[0].label}
              </div>

              <p className="or">OR</p>

              <div
                className="option active"
                onClick={() => selectCampaignType(campaignTypeOptions[1])}
              >
                {campaignTypeOptions[1].label}
              </div>
            </div>
          )}

          {step === 2 &&
            campaignType?.value === campaignTypeOptions[0]?.value && (
              <div className="modal_body step_two">
                <FormSelect
                  name="filterSelect"
                  defaultValue={segment}
                  placeholder="Select Segment"
                  options={segmentOptions}
                  onChange={(value: any) => setSegment(value)}
                />

                <Button
                  text="Generate Campaign Details"
                  onClick={handleGenerateCampaignDetails}
                  loading={isCreatingCampaign}
                  loadingText="Generating details..."
                  disabled={!segment || isCreatingCampaign}
                />
              </div>
            )}

          {step === 2 &&
            campaignType?.value === campaignTypeOptions[1]?.value && (
              <div className="modal_body step_two_two">
                <EnterPromptForm
                  generateWithAI={generateWithAI}
                  promptText={promptText}
                  setPromptText={setPromptText}
                  hideAskAI={true}
                  isCreating={isCreatingCampaign}
                  generateDetails={handleGenerateCampaignDetails}
                />
              </div>
            )}

          {step === 3 && (
            <div className="modal_body step_three">
              <p className="campaign_title_label">CAMPAIGN TITLE</p>
              <p className="campaign_title">{generatedData?.campaign_title}</p>

              <div className="info">
                {campaignType?.value === campaignTypeOptions[0]?.value ? (
                  <div className="item">
                    <p className="label">TO</p>
                    <p className="value">
                      <div className="dot"></div>{" "}
                      {segment?.label || segmentOptions?.[0].label}
                    </p>
                  </div>
                ) : (
                  <div className="item">
                    <p className="label">CAMPAIGN GOAL</p>
                    <p className="value">{generatedData?.campaign_goal}</p>
                  </div>
                )}

                <div className="item">
                  <p className="label">NO OF RECIPIENTS</p>
                  <p className="value">
                    {Number(
                      generatedData?.campaign_recipients?.length
                    ).toLocaleString("en-US")}
                  </p>
                </div>
              </div>

              <div className="marketing">
                <p className="title">AGENT ANALYSIS AND INSIGHTS</p>

                <p className="feedback">{rationaleContent}</p>
              </div>

              <div className="message_container">
                <SectionHeader
                  title={generatedData?.campaign_subject}
                  rightSide={
                    <>
                      <Button
                        leftIcon="regenerate"
                        text="Rewrite"
                        className="btn_text"
                        onClick={handleRewriteMessageBody}
                        disabled={isRewritingMessageBody}
                      />
                    </>
                  }
                />

                {isRewriting || isRewritingMessageBody ? (
                  <Loader />
                ) : (
                  <div
                    className="message_text"
                    dangerouslySetInnerHTML={{
                      __html: campaignMsgContent,
                    }}
                  ></div>
                )}
              </div>

              {isRewriting ? (
                ""
              ) : !isModifying ? (
                <div className="modal_action">
                  <Button
                    text="Accept"
                    onClick={() => handleSubmit("scheduled")}
                    disabled={isSavingCampaign}
                  />

                  {campaignType?.value === campaignTypeOptions[1]?.value && (
                    <Button
                      text="Modify"
                      className="btn_tertiary_primary"
                      onClick={() => setIsModifying(true)}
                      disabled={isSavingCampaign}
                    />
                  )}

                  <Button
                    text="Save as draft"
                    className="btn_draft"
                    onClick={() => handleSubmit("draft")}
                    disabled={isSavingCampaign}
                  />

                  <Button
                    text="Decline"
                    className="btn_error"
                    onClick={() => {}}
                    disabled={isSavingCampaign}
                  />
                </div>
              ) : (
                <div className="enter_prompt_form_with_margin">
                  <EnterPromptForm
                    promptText={promptText}
                    setPromptText={setPromptText}
                    isCreating={isCreatingCampaign}
                    hideAskAI={true}
                    generateDetails={handleGenerateCampaignDetails}
                  />
                </div>
              )}
            </div>
          )}

          {step === 4 && (
            <div className="modal_body step_four">
              <div className="generating_data">
                <p className="title">Generating Campaign</p>

                <Loader />

                <p className="please_wait">Please Wait...</p>
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default NewCampaignModal;
