import { useState, useEffect } from "react";
import { Modal } from "reactstrap";

import { useAppDispatch } from "../../redux";

import { getAISyncToVectorDBAction } from "../../redux/dashboard/settings-slice";
import { setAlert } from "../../redux/components/components-slice";

import SectionHeader from "../section-header";

// import Icon from "../../assets/svg";
import Loader from "../loader";

import { didYouKnowTexts } from "../../utils/mockdata";

type Props = {
  isOpen: boolean;
  close: any;
};

const StoreDataSynchingModal = ({ isOpen, close }: Props) => {
  const dispatch = useAppDispatch();

  const [knowText, setKnowText] = useState(didYouKnowTexts[0]);
  const [knowTextCount, setKnowTextCount] = useState(0);
  const [aISyncCount, setAISyncCount] = useState(0);
  const [showAISyncError, setShowAISyncError] = useState(false);
  const [isSyncingStore, setIsSyncingStore] = useState(false);

  useEffect(() => {
    handleAISyncToVectorDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const totalIndex = didYouKnowTexts?.length - 1;
    const nextIndex = knowTextCount === totalIndex ? 0 : knowTextCount + 1;

    setTimeout(() => {
      setKnowText(didYouKnowTexts[nextIndex]);
      setKnowTextCount(nextIndex);
    }, 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowText]);

  const handleAISyncToVectorDB = async () => {
    setIsSyncingStore(true);

    await dispatch(getAISyncToVectorDBAction()).then((resp: any) => {
      if (resp?.isCompleted === true) {
        dispatch(setAlert(true, "success", "Your dashboard is ready 😄 "));
        close?.();
        // window.location.reload();
      } else if (resp?.isCompleted === false) {
        reRunAISyncToVectorDB();
      } else {
        setShowAISyncError(true);
        setIsSyncingStore(false);
      }
    });
  };
  const reRunAISyncToVectorDB = async () => {
    if (aISyncCount > 2) {
      setShowAISyncError(true);
    } else {
      setTimeout(() => {
        setAISyncCount(aISyncCount + 1);
        handleAISyncToVectorDB();
      }, 3000);
    }
  };

  return (
    <Modal className="store_data_synching_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
        // rightSide={
        //   <>
        //     <div className="icon" onClick={close}>
        //       <Icon name="close" />
        //     </div>
        //   </>
        // }
        />
      </div>

      <div className="modal_body">
        <div className="">
          <p className="header">Welcome to Konvas AI</p>
          <p className="sub_header">
            I am currently connecting to your store to get some data.{" "}
          </p>

          {isSyncingStore ? (
            <div className="loader">
              <Loader />
              <p>Gathering data...</p>
            </div>
          ) : showAISyncError ? (
            <div>
              <p>
                &#10071; Sorry, we encountered an error.
                <br />
                Refresh browser to retry
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="know_texts">
            &#128161; <p>{knowText}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StoreDataSynchingModal;
