import { useState } from "react";
import classNames from "classnames";
import SunEditor from "suneditor-react";

import CampaignFooter from "./campaign-footer";
import CampaignReport from "./campaign-report";

import Icon from "../../assets/svg";

import Button from "../../components/button";
import DropDown from "../../components/dropdown";
import FormInput from "../../components/form-input";
import Loader from "../../components/loader";
import SectionHeader from "../../components/section-header";

import CampaignMessagePreviewModal from "../../components/modal/campaign-message-preview-modal";

type Props = {
  isFetchingCampaignData: boolean;
  selectedCampaign: any;
  analytics: any;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<any>>;
  setIsCampaignAiAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCampaignRecepientModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  rewriteMsg: () => Promise<void>;
  setIsCampaignScheduleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dateDisplay: (startDate: Date, endDate: Date) => string;
  contentOptions: { label: string; action: () => void }[];
  isRewritingMsg: boolean;
  isEditingMsgContent: boolean;
  msgSubject: string;
  setMsgSubject: (value: React.SetStateAction<string>) => void;
  msgContent: string;
  setMsgContent: React.Dispatch<React.SetStateAction<string>>;
  getSunEditorInstance: (sunEditor: any) => void;
  sunEditorOptions: { fontSize: number[]; buttonList: string[][] };
  setIsEditingMsgContent: (value: React.SetStateAction<boolean>) => void;
  setCampaignInitialSubjectAndContent: () => void;
  updateCampaign: () => Promise<void>;
  isStartingCampaign: boolean;
  startCampaign: () => void;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pauseCampaign: () => void;
  continueCampaign: () => void;
  isPausingCampaign: boolean;
  isContinuingCampaign: boolean;
  isEndingCampaign: boolean;
  endCampaign: () => void;
};

const CampaignDetails = ({
  isFetchingCampaignData,
  selectedCampaign,
  analytics,
  setSelectedCampaign,
  setIsCampaignAiAgentModalOpen,
  setIsCampaignRecepientModalOpen,
  rewriteMsg,
  setIsCampaignScheduleModalOpen,
  dateDisplay,
  contentOptions,
  isRewritingMsg,
  isEditingMsgContent,
  msgSubject,
  setMsgSubject,
  msgContent,
  setMsgContent,
  getSunEditorInstance,
  sunEditorOptions,
  setIsEditingMsgContent,
  setCampaignInitialSubjectAndContent,
  updateCampaign,
  isStartingCampaign,
  startCampaign,
  setIsConfirmationModalOpen,
  pauseCampaign,
  continueCampaign,
  isPausingCampaign,
  isContinuingCampaign,
  isEndingCampaign,
  endCampaign,
}: Props) => {
  const [
    isCampaignMessagePreviewModalOpen,
    setIsCampaignMessagePreviewModalOpen,
  ] = useState(false);

  return (
    <div
      className={classNames("campaign_details", {
        is_visible: isFetchingCampaignData || selectedCampaign,
      })}
    >
      {isFetchingCampaignData ? (
        <Loader />
      ) : (
        selectedCampaign && (
          <>
            {selectedCampaign.status !== "draft" &&
              selectedCampaign.status !== "scheduled" && (
                <CampaignReport
                  reportData={{
                    ...analytics,
                    ...selectedCampaign,
                  }}
                  close={() => setSelectedCampaign(null)}
                />
              )}

            <SectionHeader
              goBack={true}
              goBackOnClick={() => setSelectedCampaign(null)}
              title="Campaign Message"
              printer
              rightSide={
                selectedCampaign.status === "scheduled" ? (
                  <Button
                    text={"Preview"}
                    className="preview"
                    onClick={() => setIsCampaignMessagePreviewModalOpen(true)}
                  />
                ) : (
                  <></>
                )
              }
            />

            <div className="header">
              <div className="side">
                <div className="product">
                  <p>
                    Title: <span>{selectedCampaign?.campaign_title}</span>
                  </p>
                  <div className="dot"></div>
                </div>

                <div className="product">
                  <p>
                    AI Agent:{" "}
                    <span
                      className="link"
                      onClick={() => setIsCampaignAiAgentModalOpen(true)}
                    >
                      View Agent's Analysis, Rationale and Recommendations for
                      this Campaign
                    </span>
                  </p>
                  <div className="dot"></div>
                </div>

                <div className="product">
                  <p>
                    To:{" "}
                    <span
                      className="link"
                      onClick={() => setIsCampaignRecepientModalOpen(true)}
                    >
                      {Number(
                        JSON.parse(selectedCampaign?.campaign_recipients)
                          ?.length
                      ).toLocaleString("en-US")}{" "}
                      recipients
                    </span>
                  </p>
                  <div className="dot"></div>
                </div>
              </div>

              <div className="side">
                {/* <div className="icon" onClick={() => {}}>
                <Icon name="arrowForward" />
              </div>
              <div className="icon" onClick={() => {}}>
                <Icon name="star" />
              </div> */}
              </div>
            </div>

            <div className="content">
              {selectedCampaign.status === "draft" && (
                <div className="top">
                  <div className="left">
                    <Button
                      text={"Preview"}
                      onClick={() => setIsCampaignMessagePreviewModalOpen(true)}
                    />
                    <div className="regenerate" onClick={() => rewriteMsg()}>
                      <Icon name="regenerate" />
                      Rewrite message?
                    </div>
                  </div>

                  <div
                    className="date"
                    onClick={() => setIsCampaignScheduleModalOpen(true)}
                  >
                    <Icon name="calendar" />
                    {dateDisplay(
                      new Date(selectedCampaign?.start_date),
                      new Date(selectedCampaign?.end_date)
                    )}
                  </div>
                </div>
              )}

              <div className="body">
                {selectedCampaign.status === "draft" && (
                  <div className="actions">
                    <DropDown toggler={<Icon name="dotsH" />}>
                      {contentOptions?.map((item: any, i: any) => (
                        <div
                          key={i}
                          className="dropdown-item"
                          onClick={item?.action}
                        >
                          {item?.label}
                        </div>
                      ))}
                    </DropDown>
                  </div>
                )}

                {isRewritingMsg ? (
                  <Loader />
                ) : (
                  <div className="body_content">
                    {!isEditingMsgContent ? (
                      <p className="subject">{msgSubject}</p>
                    ) : (
                      <FormInput
                        type="text"
                        placeholder="Subject"
                        value={msgSubject}
                        readOnly={false}
                        onChange={(e) => setMsgSubject(e.target.value)}
                      />
                    )}

                    {!isEditingMsgContent ? (
                      <div className="message">
                        <div
                          className={classNames("message_text", {
                            draft: selectedCampaign.status === "draft",
                          })}
                          onClick={() => setIsEditingMsgContent(true)}
                          dangerouslySetInnerHTML={{
                            __html: msgContent,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <>
                        <SunEditor
                          getSunEditorInstance={getSunEditorInstance}
                          name="message"
                          placeholder="Type your message here..."
                          setOptions={sunEditorOptions}
                          defaultValue={msgContent}
                          onChange={(content) => setMsgContent(content)}
                        />

                        <div className="actions">
                          <Button
                            text="Cancel"
                            className="btn_secondary"
                            onClick={() => {
                              setIsEditingMsgContent(false);
                              setCampaignInitialSubjectAndContent();
                            }}
                          />
                          <Button text="Save" onClick={updateCampaign} />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!isEditingMsgContent &&
              selectedCampaign.status !== "completed" && (
                <CampaignFooter
                  campaignData={selectedCampaign}
                  contentOptions={contentOptions}
                  isStartingCampaign={isStartingCampaign}
                  startCampaign={startCampaign}
                  setIsCampaignScheduleModalOpen={
                    setIsCampaignScheduleModalOpen
                  }
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                  pauseCampaign={pauseCampaign}
                  continueCampaign={continueCampaign}
                  isPausingCampaign={isPausingCampaign}
                  isContinuingCampaign={isContinuingCampaign}
                  isEndingCampaign={isEndingCampaign}
                  endCampaign={endCampaign}
                />
              )}
          </>
        )
      )}
      {selectedCampaign ? (
        <CampaignMessagePreviewModal
          isOpen={isCampaignMessagePreviewModalOpen}
          close={() => setIsCampaignMessagePreviewModalOpen(false)}
          campaign={selectedCampaign}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CampaignDetails;
